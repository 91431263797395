.navbar {
  border-bottom: 1px solid black;
}

.img-fluid {
  max-width: 50%;
  max-height: 50%;
}

.img-fluid-annam {
  max-width: 13%;
  max-height: 30%;
  float: right;
}

.div-left-fluid {
  width: 20%;
}

@media (max-width: 768px) {
  .img-fluid {
    max-width: 100%;
    max-height: 100%;
  }
  .img-fluid-annam {
    height: 50px;
    max-width: 100%;
  }

  .div-left-fluid {
    width: 30%;
  }
}
