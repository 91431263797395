.simulador {
  margin-bottom: 20px;
}

.observacoes {
  margin-top: 20px;
}

.observacoes h2 {
  margin-bottom: 10px;
}

.observacoes p {
  margin-bottom: 5px;
}

body {
  margin-left: 5px;
}

.minha-tabela,
.observacoes {
  margin-left: 0;
}

.valor-patrimonial {
  display: flex;
  justify-content: space-between;
}

.dados-alignment {
  text-align: right;
}

.indicador-medida {
  text-align: center;
  vertical-align: middle;
}
.select-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  /* flex-wrap: wrap; */
}

.form-select {
  flex-grow: 1;
  margin-right: 10px;
}

.minha-tabela {
  width: 100%;
}

thead {
  display: table-header-group;
  vertical-align: middle;
}

.verical-information {
  /* display: table-header-group; */
  vertical-align: middle;
}

.align-vertically-center {
  vertical-align: middle;
  text-align: center;
}

.select-container label {
  flex: 1 1 50%;
  margin-right: 10px;
}

.patrimonial-value-fontsize {
  max-width: 100%;
  text-align: center;
}
/* 
.form-container {
  margin: 0 auto;
  max-width: 800px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
} */

.div_divider {
  height: 104px;
}

@media (max-width: 600px) {
  .select-container {
    flex-direction: column;
    align-items: stretch;
  }

  .form-select {
    /* margin-right: 0; */
    margin: 10px auto;
    margin-bottom: -10px;
  }

  .simulador-header {
    text-align: center;
  }

  .div_divider {
    height: 0px;
  }
}
